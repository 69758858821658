import { Item } from '@sg-bootstrap/components';
import { Bootstrap } from '../types/Bootstrap';
import { GreenBookStatus } from '../types/Enums';
import { IInterval } from '../types/Interval';

export interface ItemWithColor extends Item {
  color?: Bootstrap.Color;
}

export interface ItemWithIntervalDate extends ItemWithColor {
  interval: IInterval<string>;
  description: string;
}

export interface IAmountInEUR extends Item {
  interval: IInterval<number>;
}

export const GREEN_BOOK_LIST: ItemWithColor[] = [
  { label: 'book.status.candidate', key: GreenBookStatus.CANDIDATE, color: 'info' },
  { label: 'book.status.restricted', key: GreenBookStatus.RESTRICTED, color: 'danger' },
  { label: 'book.status.rejected', key: GreenBookStatus.REJECTED, color: 'danger' },
  { label: 'book.status.standard retired', key: GreenBookStatus.STANDARD_RETIRED, color: 'danger' },
  { label: 'book.status.standard validated', key: GreenBookStatus.STANDARD_VALIDATED, color: 'success' },
  { label: 'book.status.unknown', key: GreenBookStatus.UNKNOWN, color: 'warning' },
];

export const CATEGORY_IMPACT: Item[] = [
  { label: 'Sofa', key: 'sofa' },
  { label: 'Api plateform', key: 'api-plateform' },
  { label: 'Application server', key: 'application-server' },
  { label: 'Bi & Analitics', key: 'bi' },
  { label: 'BS support', key: 'bs-support' },
  { label: 'Business Process MGT', key: 'bpm' },
  { label: 'Compute & container', key: 'compute-container' },
  { label: 'Connectivity', key: 'connectivity' },
  { label: 'Data integration & interoperability', key: 'data-integration-interoperability' },
  { label: 'Data management', key: 'data-management' },
  { label: 'Datatbase', key: 'datatbase' },
  { label: 'Developper experience', key: 'devlopper-experience' },
  { label: 'Document & content MGT', key: 'document-content-mgt' },
  { label: 'Event & Exchange', key: 'event-exchange' },
  { label: 'Information MGT', key: 'information-mgt' },
  { label: 'Is security services', key: 'security-service' },
  { label: 'MGT of is services', key: 'mgt-services' },
  { label: 'Observability', key: 'observability' },
  { label: 'Storage', key: 'storage' },
  { label: 'Workplace', key: 'workplace' },
];

export const POSITION_CT_LIST: ItemWithColor[] = [
  { label: 'assessment.status.ok', key: 'ok', color: 'success' },
  { label: 'assessment.status.bus', key: 'bus', color: 'warning' },
  { label: 'assessment.status.nok', key: 'nok', color: 'danger' },
  { label: 'assessment.status.nok-derogated', key: 'nok-derogated', color: 'warning' },
  {
    label: 'assessment.status.nok-remediated',
    key: 'nok-remediated',
    color: 'warning',
  },
  { label: 'assessment.status.nok-study', key: 'nok-study', color: 'warning' },
  { label: 'assessment.status.not-sw', key: 'not-sw', color: 'danger' },
  { label: 'assessment.status.to-do', key: 'to-do', color: 'danger' },
];

export const COMPLIANCE_ASSESSMENT_LIST: ItemWithColor[] = [
  { label: 'assessment.status.to-decom', key: 'to-decom', color: 'danger' },
  { label: 'assessment.status.to-renew', key: 'to-renew', color: 'warning' },
  { label: 'assessment.status.to-check', key: 'to-check', color: 'warning' },
  { label: 'assessment.status.to-decrease', key: 'to-decrease', color: 'warning' },
  { label: 'assessment.status.to-transfere', key: 'to-transfere', color: 'warning' },
  { label: 'assessment.status.to-do', key: 'to-do', color: 'secondary' },
];

export const SOFWTARE_CATEGORY_LIST: ItemWithColor[] = [
  { label: 'BI', key: 'bi', color: 'info' },
  { label: 'BPM', key: 'bpm', color: 'info' },
  { label: 'BS Support', key: 'bs-support', color: 'info' },
  { label: 'Developer experience', key: 'developer-experience', color: 'info' },
  { label: 'Developer tool', key: 'developer-tool', color: 'info' },
  { label: 'Mainframe', key: 'mainframe', color: 'info' },
  { label: 'SOFA', key: 'sofa', color: 'info' },
  { label: 'Workplace', key: 'workplace', color: 'info' },
  { label: 'Other', key: 'other', color: 'info' },
];

export const CONTRACT_TYPE_LIST: Item[] = [
  { label: 'Location', key: 'location' },
  { label: 'Licence', key: 'licence' },
  { label: 'Maintenance', key: 'maintenance' },
  { label: 'Other', key: 'other' },
];

export const ORIGIN_LIST: Item[] = [
  { label: 'origin.ariba', key: 'ariba' },
  { label: 'origin.user', key: 'user' },
  { label: 'origin.other', key: 'other' },
];

export const AMOUNT_IN_EUR_LIST: IAmountInEUR[] = [
  {
    key: 'less-than-100k',
    label: 'Less than 100k€',
    interval: {
      min: undefined,
      max: 100000.0,
    },
  },
  {
    key: 'less-than-500k',
    label: 'Less than 500k€',
    interval: {
      min: undefined,
      max: 500000.0,
    },
  },
  {
    key: 'more-than-500k',
    label: 'More than 500k€',
    interval: {
      min: 500000.0,
      max: undefined,
    },
  },
];

export const ITEM_PER_PAGE = 48;
export const ITEM_PER_TABLE = 15;
export const GREENBOOK_FILE_NAME = '/data/greenbook-Mon-Jan-23-2023.csv';
export const GREENBOOK_URL = 'https://devportal.group.socgen/greenbooks/';
export const PAGE_PAGINATION = 10;

export enum QUERY_KEYS {
  SOFTWARES = 'softwares.paginated',
  SOFTWARES_ALL = 'softwares.all',
  SOFTWARE = 'software',
  SOFTWARE_HISTORY = 'software.history',
  EDITORS_SOFTWARES_NAMES = 'editors.softwares.names',
  CONTRACTS = 'contracts.paginated',
  CONTRACT = 'contract',
  CONTRACT_HISTORY = 'contract.history',
  CONTRACTS_ASSESSMENT = 'contracts.assessment',
  GREEN_BOOK = 'green-book',
  BLUE_BOOK = 'blue-book',
  SOURCING_HUB_ACTIVE_SOFTWARES = 'sourcing-hub.active-softwares',
  SOURCING_HUB_SUMMARY = 'sourcing-hub.summary',
  SOURCING_HUB_SOFTWARES = 'sourcing-hub.softwares',
  SOURCING_HUB_SOFTWARE_DETAILS = 'sourcing-hub.software-details',
  SOURCING_HUB_SUPPLIERS = 'sourcing-hub.suppliers',
  SOURCING_HUB_SUPPLIER_DETAILS = 'sourcing-hub.supplier-details',
  TRIBES = 'tribes',
  ARIBA_PURCHASE_REQUESTS = 'ariba.purchase-requests',
  ARIBA_PURCHASE_ORDERS = 'ariba.purchase-orders',
  ARIBA_INVOICES = 'ariba.invoices',
  ARIBA_SOFTWARES = 'ariba.softwares',
  ARIBA_SYNCHRONIZATION = 'ariba.synchronization',
  NOTIFICATIONS = 'notifications',
}

export const UNKNOWN = 'unknown';

export type BrowsingMode = 'read' | 'edit' | 'create';

export interface PropsWithBrowsingMode {
  mode: BrowsingMode;
}
